import React from "react";
import AppWrapper from "carbon-react/lib/components/app-wrapper";
import NavigationBar from "carbon-react/lib/components/navigation-bar";
import LinkList from "./components/linklist";

import "./App.scss";
import MainMenu from "./components/mainmenu/mainmenu";

function App() {
  return (
    <AppWrapper>
      <NavigationBar>
        <MainMenu />
      </NavigationBar>

      <div className="App">
        <header className="App-header">
          <br />
          <div className="App-header-title">Urlaub</div>
        </header>
        <LinkList />
      </div>
    </AppWrapper>
  );
}

export default App;
