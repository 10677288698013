import React, { Component, Fragment } from "react";
import { Menu, MenuItem } from "carbon-react/lib/components/menu";

// https://carbon.sage.com/components/menu

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, data: null };
  }

  componentDidMount() {
    fetch("data.json")
      .then(response => response.json())
      .then(json => this.setState({ loading: false, data: json }));
  }

  renderSubMenu = (links, place) => {
    const items = [];
    let keyVal = 1;

    for (let i = 0; i < links.length; i++) {
      if (place === links[i].place) {
        items.push(
          <MenuItem key={keyVal++} href={links[i].href}>
            {links[i].text}
          </MenuItem>
        );
      }
    }
    return items;
  };

  renderMenu = data => {
    const content = [];
    let keyVal = 1;
    for (let i = 0; i < data.places.length; i++) {
      content.push(
        <MenuItem key={keyVal++} submenu={data.places[i]}>
          {this.renderSubMenu(data.links, data.places[i])}
        </MenuItem>
      );
    }
    return content;
  };

  render() {
    const { loading, data } = this.state;

    return (
      <Fragment>
        {loading ? "Bitte warten..." : <Menu>{this.renderMenu(data)}</Menu>}
      </Fragment>
    );
  }
}

export default MainMenu;
