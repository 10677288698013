import React, { Component, Fragment } from "react";

import Content from "carbon-react/lib/components/content";
import Link from "carbon-react/lib/components/link";
import Icon from "carbon-react/lib/components/icon";

class LinkList extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, title: null };
  }

  componentDidMount() {
    fetch("data.json")
      .then(response => response.json())
      .then(json => this.setState({ loading: false, data: json }));
  }

  renderListLinks = (links, place) => {
    const items = [];
    let keyVal = 1;

    items.push(<br key={keyVal++} />);
    for (let i = 0; i < links.length; i++) {
      if (place === links[i].place) {
        items.push(
          <Link key={keyVal++} href={links[i].href}>
            {links[i].text}
          </Link>
        );
        items.push(<br key={keyVal++} />);
      }
    }
    return items;
  };

  renderList = data => {
    const content = [];
    let keyVal = 1;

    for (let i = 0; i < data.places.length; i++) {
      content.push(<Icon key={keyVal++} type="favourite" />);
      content.push(
        <Content key={keyVal++} title={data.places[i]}>
          {this.renderListLinks(data.links, data.places[i])}
        </Content>
      );
      content.push(<br key={keyVal++} />);
    }
    return content;
  };

  render() {
    const { loading, data } = this.state;

    return (
      <Fragment>{loading ? "Bitte warten..." : this.renderList(data)}</Fragment>
    );
  }
}

export default LinkList;
